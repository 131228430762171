/*
 * @Author: your name
 * @Date: 2020-12-11 10:19:32
 * @LastEditTime: 2021-02-03 17:29:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\api\user.js
 */
import { post, fetch, deletes, patch, put } from '@/utils/http'

//首页统计相关核销码数据
export function homePageCount(params) {
  return fetch('/cancelCode/homePageCount', params);
}

//费用结算明细-结算统计
export function closeCount(params) {
  return fetch('/cancelCode/closeCount', params);
}
//费用结算明细-结算统计河南
export function countOilCoupon(params) {
  return fetch('/cancelCode/countOilCoupon', params);
}
//统计机构积分
export function countOrgIntegral(params) {
  return fetch('/cancelCode/countOrgIntegral', params);
}
//统计积分总数
export function countIntegralTotal(params) {
  return fetch('/cancelCode/countIntegralTotal', params);
}

//按日期统计机构积分
export function countOrgIntegralByDate(params) {
  return fetch('/cancelCode/countOrgIntegralByDate', params);
}
//按日期统计积分总数
export function countIntegralTotalByDate(params) {
  return fetch('/cancelCode/countIntegralTotalByDate', params);
}